import loadable from "@loadable/component"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useRef, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/black-and-white.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import climate from "../../images/climate.png"
import plan from "../../images/plan.png"
import select from "../../images/select.png"
import tree from "../../images/tree.png"
import wiggle from "../../images/wiggle.svg"
import todo from "../../images/todo.png"
import food from "../../images/food.png"
import transportation from "../../images/transportation.png"
import weather from "../../images/weather.png"
import chat from "../../images/chat.png"
import happy from "../../images/happy.png"
import accommodation from "../../images/accommodation.png"
import work from "../../images/working-designer.png"
// import { analytics } from "../firebase/firebase"
import Image from "../image"
import { AuthUserContext } from "../session"
import logoSVG from "./../../images/logo.jpg"
import testimonial1 from "./../../images/testimonial1.png"
import testimonial2 from "./../../images/testimonial2.jpg"
import testimonial3 from "./../../images/testimonial3.png"
import paris from "./../../images/paris.jpg"
import "./landingpage.scss"
import ShowPlanATrips from "../ShowPlanATrips/ShowPlanATrips"
const TestimonialsCardComponent = loadable(() =>
  import("../TestimonialsCardComponent/TestimonialsCardComponent")
)

const FetchAsyncDesignerCards = loadable(() =>
  import("../DesignerCards/FetchAsyncDesignerCards")
)
const ArticleCards = loadable(() => import("../ArticleCards/ArticleCards"))
const Accordion = loadable(() => import("../Accordion/Accordion"))
// const HowItWorks = loadable(() => import("../HowItWorks/HowItWorks"))
const NewsletterSubscription = loadable(() =>
  import("../NewsletterSubscription/NewsletterSubscription")
)
let backgroundPosition = 55
function LandingPage() {
  const [atTop, setAtTop] = useState(true)
  const [state, setState] = useState("none")
  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)
  const authContext = useContext(AuthUserContext)

  const [showVideo, setShowVideo] = useState(false)
  const [activePATtab, setActivePATtab] = useState("popular")
  const [blogPostURLs, setBlogPostURLs] = useState([
    "https://blog.voyayge.com/the-complete-guide-to-cappadocia-balloon-festival-turkey/",
    "https://blog.voyayge.com/countries-americans-can-travel-to-now/",
    "https://blog.voyayge.com/10-unforgettable-road-trips-across-national-parks-in-the-usa/",
    "https://blog.voyayge.com/patagonia/",
  ])

  useEffect(() => {
    document.addEventListener("scroll", positionSetter)
    return () => window.removeEventListener("scroll", positionSetter)
  }, [])
  const positionSetter = e => {
    if (window.scrollY === 0) {
      setAtTop(true)
    } else {
      setAtTop(false)
    }
  }

  const faqWrapper = qNa => {
    return <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>{qNa}</div>
  }

  var scrollRef = useRef(null)
  var scrollRefStart = useRef(null)
  var logoRef = useRef(null)
  var travelerDetailsRef = useRef(null)
  var priceCardRef = useRef(null)
  var trigger1 = useRef(null)

  return (
    <>
      <div ref={e => (scrollRefStart = e)}></div>

      <section className="section" style={{ minHeight: "100vh" }}>
        <div className="hero-image-primary">
          <Image
            src={"worldMap"}
            style={{ position: "unset" }}
            imgStyle={{
              objectPosition: `${backgroundPosition}% center`,
            }}
            alt="voyayge hero image"
          ></Image>
        </div>

        <div className="hero-body center">
          <div
            className="has-text-centered hero-container"
            ref={e => (logoRef = e)}
            style={{
              opacity: "1",
              overflow: "hidden",
              maxWidth: "1040px",
              textAlign: "left",
              margin: "0 auto",
            }}
          >
            <div
              className="hero-logo-small"
              style={{
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              <LazyLoadImage
                src={logoSVG}
                alt="voyayge company logo"
                style={{ opacity: "1", display: "none" }}
                effect="blur"

                // width="175px"
                // height="25px"
              />
            </div>
            <p
              className="hero-caption"
              style={{
                color: "rgba(74,74,74)",
                // color: "white",
                lineHeight: "1.1",
                textAlign: "left",
              }}
            >
              Take the{" "}
              <span
                className="yay"
                style={{
                  color: "#fec600",
                  // fontFamily: "Montserrat",
                }}
              >
                stress away
              </span>{" "}
              from your vacation planning
              <span style={{ color: "#ffc600" }}>.</span>
              <span
                // className="gradient-underline"
                style={{
                  textDecorationColor: "#fec600",
                  textDecorationLine: "underline",

                  // textDecorationStyle: "wavy",
                }}
              ></span>{" "}
            </p>

            <p
              className="hero-caption-2"
              style={{
                textTransform: "",
                color: "rgba(74,74,74)",
                // color: "white",
              }}
            >
              Get your custom travel plan{" "}
              <span
                // className="gradient-underline"
                style={{
                  textDecorationColor: "#fec600",
                  textDecorationLine: "underline",
                  textDecorationThickness: "3px",
                  // textDecorationStyle: "wavy",
                }}
              >
                built from scratch
              </span>{" "}
              by an expert travel planner
              {/* <strong
                style={{
                  // fontSize: "20px",
                  fontWeight: "700",
                  fontFamily: "Montserrat",
                }}
              >
                {" "}
                voyay!ge
              </strong>{" "} */}
              <span style={{ color: "" }}>.</span>
              {/* Get your personalized travel plan crafted! */}
              {/* Democratizing bespoke travel planning. */}
              {/* Platform for bespoke travel itineraries */}
            </p>
            <div
              style={
                {
                  // maxWidth: "250px",
                  // margin: "30px auto",
                }
              }
              className="hero-button-container"
            >
              {/* <HowItWorks /> */}
              <button
                // className="button is-medium is-warning is-inverted brand-shadow"
                className="landing-page-button button is-warning brand-shadow "
                style={{
                  width: "168px",
                  fontWeight: "600",
                  letterSpacing: "0.5px",
                  marginTop: "15px",
                }}
                onClick={e => {
                  // if (authContext) navigate("#our-approach")
                  // else {
                  //   // analytics.logEvent("hero_signup_click")
                  //   // navigate("/signup-as-traveler")
                  // }
                  navigate("#our-approach")
                }}
              >
                Learn more
              </button>
            </div>
          </div>
        </div>
        <span
          className="scroll-btn"
          ref={e => (scrollRef = e)}
          style={{ position: "absolute", bottom: "10px" }}
        >
          <a href="#our-approach">
            <i
              style={{ color: "#fec600" }}
              className="fal fa-angle-double-down fa-3x"
            ></i>
          </a>
        </span>
      </section>

      <section style={{ display: "none" }}>
        <div className="container">
          <div style={{ maxWidth: "90%", margin: "20px auto" }} id="howitworks">
            <div
              className="wistia_responsive_padding"
              style={{ padding: "56.25% 0 0 0", position: "relative" }}
            >
              <div
                className="wistia_responsive_wrapper"
                style={{
                  height: "100%",
                  left: "0",
                  position: "absolute",
                  top: "0",
                  width: "100%",
                }}
              >
                <div
                  className="wistia_embed wistia_async_3ui39gxytm videoFoam=true"
                  style={{
                    height: "100%",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div
                    className="wistia_swatch"
                    style={{
                      height: "100%",
                      left: "0",
                      opacity: "0",
                      overflow: "hidden",
                      position: "absolute",
                      top: "0",
                      transition: "opacity 200ms",
                      width: "100%",
                    }}
                  >
                    <img
                      src="https://fast.wistia.com/embed/medias/3ui39gxytm/swatch"
                      style={{
                        filter: "blur(5px)",
                        height: "100%",
                        objectFit: "contain",
                        width: "100%",
                      }}
                      alt="voyayge - how it works - video"
                      aria-hidden="true"
                      // onload="this.parentNode.style.opacity=1;"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section is-medium gradient-shape"
        style={{
          borderRadius: "0px",
          minHeight: "100vh",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
        ref={e => (trigger1 = e)}
      >
        <div className="container" id="our-approach">
          <h2 className="section-header">
            <span
              style={{
                color: "#4a4a4a",
              }}
            >
              OUR<br></br>APPROACH
            </span>
            <span
              style={{
                color: "#fec600",
              }}
            >
              .
            </span>
          </h2>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "1.8",
              marginTop: "15px",
              color: "rgba(74,74,74,0.7)",
            }}
          >
            Vacation planning is stressful
            {/* </span>{" "} */}. It takes so much time and effort to perform
            all the research to craft a perfect travel plan. This effort further
            increases when it's for a family or a group trip.
            {/* At{" "}
            <strong
              style={{
                fontSize: "20px",
                fontWeight: "400",
                fontFamily: "Montserrat",
              }}
            >
              {" "}
              voyay!ge
            </strong>{" "}
            ,  */}{" "}
            We understand that you would rather spend your time on things that
            bring you joy in life. When using{" "}
            <strong
              style={{
                fontSize: "18px",
                fontWeight: "400",
                // fontFamily: "Montserrat",
              }}
            >
              {" "}
              voyayge
            </strong>
            , you'll receive a carefully crafted travel itinerary based on your
            preferences. Your bespoke travel plan is built from scratch and is
            unique to you as its tailor-made by a trip designer keeping in mind
            your preferences.
            <br />
            <br />{" "}
            <span
              style={{
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "1.7",
                marginTop: "15px",
                display: "none",
                // textTransform: "uppercase",
              }}
            >
              Your plan includes:{" "}
            </span>
            <div
              className="whatisincluded_container"
              style={{ display: "none" }}
            >
              <div className="whatisincluded_item">
                <div
                  style={{
                    marginBottom: "10px",
                    color: "#fec600",
                  }}
                >
                  <LazyLoadImage
                    src={todo}
                    alt="Sightseeing & Activities"
                    effect="blur"
                  />
                  {/* <i className="fad fa-torii-gate fa-3x"></i> */}
                </div>
                <p>Sightseeing & Activities</p>
              </div>
              <div className="whatisincluded_item">
                <div
                  style={{
                    marginBottom: "10px",
                    color: "#fec600",
                  }}
                >
                  <LazyLoadImage
                    src={food}
                    alt="Restaurant Recommendations"
                    effect="blur"
                  />
                  {/* <i className="fad fa-utensils fa-3x"></i> */}
                </div>
                <p>Restaurant Recommendations</p>
              </div>
              <div className="whatisincluded_item">
                <div
                  style={{
                    marginBottom: "10px",
                    color: "#fec600",
                  }}
                >
                  <LazyLoadImage
                    src={transportation}
                    alt="Transportation Suggestions"
                    effect="blur"
                  />
                  {/* <i className="fad fa-route fa-3x"></i> */}
                </div>
                <p>Transportation Suggestions</p>
              </div>
              <div className="whatisincluded_item">
                {" "}
                <div
                  style={{
                    marginBottom: "10px",
                    color: "#fec600",
                  }}
                >
                  <LazyLoadImage
                    src={weather}
                    alt="Weather & Travel Tips"
                    effect="blur"
                  />
                  {/* <i className="fad fa-cloud-sun-rain fa-3x"></i> */}
                </div>
                <p>Weather & Travel Tips</p>
              </div>
              <div className="whatisincluded_item" style={{ display: "none" }}>
                {" "}
                <div
                  style={{
                    marginBottom: "10px",
                    color: "#fec600",
                  }}
                >
                  <i className="fad fa-map-marker-smile fa-3x"></i>
                </div>
                <p>Destination Travel Tips</p>
              </div>
              <div className="whatisincluded_item">
                <div
                  style={{
                    marginBottom: "10px",
                    textAlign: "center",
                    color: "#fec600",
                  }}
                >
                  <LazyLoadImage
                    src={accommodation}
                    alt="Accommodation Recommendations"
                    effect="blur"
                  />
                  {/* <i className="fad fa-hotel fa-3x"></i> */}
                </div>
                <p>Accommodation Recommendations</p>
              </div>
              <div className="whatisincluded_item">
                <div
                  style={{
                    marginBottom: "10px",
                    textAlign: "center",
                    color: "#fec600",
                  }}
                >
                  <LazyLoadImage
                    src={chat}
                    alt="Unlimited Chat With Your Trip Designer"
                    effect="blur"
                  />
                  {/* <i className="fad fa-comments-alt fa-3x"></i> */}
                </div>
                <p> Unlimited Chat With Your Trip Designer </p>
              </div>
              <div className="whatisincluded_item">
                <div
                  style={{
                    marginBottom: "10px",
                    textAlign: "center",
                    color: "#fec600",
                  }}
                >
                  <LazyLoadImage
                    src={happy}
                    alt="Unlimited Revisions Until You Are Happy"
                    effect="blur"
                  />
                  {/* <i className="fal fa-smile fa-3x"></i> */}
                </div>
                <p>Unlimited Revisions Until You Are Happy</p>
              </div>
            </div>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "1.7",
                marginTop: "15px",
                // textTransform: "uppercase",
              }}
            >
              Here's how it all works:
            </span>
            <br />
            <br />
          </h3>
          <div
            className="columns is-vcentered "
            style={{ textAlign: "center" }}
          >
            <div className="column is-1-desktop"></div>
            <div
              className="column is-5-desktop is-5-tablet"
              ref={e => (travelerDetailsRef = e)}
              style={{}}
            >
              <div
                style={{
                  maxWidth: "75px",
                  margin: "0 auto",
                  // marginTop: "50px",
                  marginBottom: "7px",
                }}
              >
                <LazyLoadImage
                  src={plan}
                  alt="create a travel plan design job"
                  effect="blur"
                />
              </div>
              <h4
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginBottom: "10px",
                }}
                className={"gradient-card"}
              >
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "800",
                    textTransform: "uppercase",
                    letterSpacing: "0.5px",
                  }}
                >
                  1. Create a design job
                </span>{" "}
              </h4>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  marginBottom: "10px",
                  lineHeight: "1.8",
                }}
              >
                You begin by creating a trip design job and provide your
                preferences and interests. These would be all the things that
                you would <strong>personally consider necessary</strong> when
                you are planning a trip.
              </p>

              <div
                style={{
                  maxWidth: "75px",
                  margin: "0 auto",
                  marginTop: "50px",
                  marginBottom: "7px",
                }}
              >
                <LazyLoadImage
                  src={select}
                  alt="pick your travel designer"
                  effect="blur"
                />
              </div>
              <h4
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginBottom: "10px",
                }}
                className={"gradient-card"}
              >
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "800",
                    textTransform: "uppercase",
                    letterSpacing: "0.5px",
                  }}
                >
                  2. Pick a travel designer
                </span>{" "}
              </h4>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  marginBottom: "10px",
                  lineHeight: "1.8",
                }}
              >
                Pick a personal travel plan designer to customize your trip. You
                will have <strong>unlimited correspondence</strong> with your
                designer to expand further or tweak your requirements.
              </p>
              {/* <p style={{ fontSize: "65px", fontWeight: "300" }}>3</p> */}
              {/* <div style={{ maxWidth: "80px", margin: "0 auto" }}>
                <img src={three} alt="" />
              </div> */}
              <div
                style={{
                  maxWidth: "75px",
                  margin: "0 auto",
                  marginTop: "50px",
                  marginBottom: "7px",
                }}
              >
                <LazyLoadImage
                  src={work}
                  alt="trip designer will do all the required travel research"
                  effect="blur"
                />
              </div>
              <h4
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginBottom: "10px",
                }}
                className={"gradient-card"}
              >
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "800",
                    textTransform: "uppercase",
                    letterSpacing: "0.5px",
                  }}
                >
                  3. Sit back & relax
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                      display: "block",
                    }}
                  >
                    We'll do all the hard work
                  </span>
                </span>{" "}
              </h4>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  marginBottom: "10px",
                  lineHeight: "1.8",
                }}
              >
                The designer will then do all the required research based on
                your preferences and craft you a personalized travel plan. You
                get to review the plan, and you{" "}
                <strong>
                  can request changes until you are completely satisfied
                </strong>
                .<br></br>
                <br></br>
                <span
                  style={{
                    // textTransform: "uppercase",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  Well,{" "}
                  <span
                    style={{
                      textDecorationColor: "#fec600",
                      // textDecorationLine: "underline",
                      /* text-decoration-style: wavy; */
                    }}
                  >
                    that's it!
                  </span>{" "}
                </span>
              </p>
            </div>
            {/* <div className="column is-0-desktop is-1-tablet"></div> */}
            {/* <button onClick={methodDoesNotExist}>Break the world</button> */}
            <div
              className="column is-5-desktop"
              ref={e => (priceCardRef = e)}
              style={{ opacity: "1" }}
            >
              <div
                // className="box price-box gradient1"
                className="box price-box"
                style={{
                  textAlign: "center",

                  // height: "520px",
                  margin: "0 auto",

                  //marginTop: "-200px",
                }}
              >
                <div style={{ display: "", justifyContent: "center" }}>
                  {/* <img style={{ maxWidth: "80px" }} src={relax}></img> */}
                  <div
                    style={{
                      lineHeight: "1",
                      textAlign: "left",
                      width: "245px",
                      margin: "0px auto",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        // marginTop: "10px",
                        // textTransform: "uppercase",
                      }}
                    >
                      Travel{" "}
                      <span
                        style={{
                          fontSize: "36px",
                          fontWeight: "900",
                          // textDecoration: "underline",
                          textTransform: "uppercase",
                          color: "rgba(74,74,74,0.8)",
                        }}
                      >
                        <br></br>stress free
                        <span
                          style={{
                            fontSize: "38px",
                            fontWeight: "900",
                            // textDecoration: "underline",
                            textTransform: "uppercase",
                            color: "#fec600",
                          }}
                        >
                          .
                        </span>
                      </span>
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "rgba(74,74,74,0.8)",
                  }}
                >
                  <p className="price-card-dollar-sign">$</p>
                  <p className="price-card-dollar-value">25</p>
                  <p className="price-card-dollar-trip-text">/day</p>
                </div>
                <div style={{ display: "", justifyContent: "center" }}>
                  {/* <img style={{ maxWidth: "80px" }} src={relax}></img> */}
                  <p
                    className="price-card-dollar-disclaimer-text"
                    style={{ letterSpacing: "0.5px" }}
                  >
                    (up to 14 days)
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    letterSpacing: "0.5px",
                  }}
                >
                  {/* <img style={{ maxWidth: "80px" }} src={relax}></img> */}
                  <img
                    src={wiggle}
                    alt=""
                    style={{ width: "50px", marginRight: "10px" }}
                  />
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      // marginTop: "30px",
                      // textTransform: "uppercase",
                      // textDecoration: "underline",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                    }}
                  >
                    YOU GET
                  </p>
                  <img
                    src={wiggle}
                    alt=""
                    style={{ width: "50px", marginLeft: "10px" }}
                  />
                </div>

                <div
                  style={{
                    marginTop: "20px",
                    textAlign: "left",
                  }}
                  className="container price-card-items"
                >
                  <ul style={{ marginBottom: "40px" }}>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "left",
                        marginBottom: "18px",
                        marginLeft: "33px",
                        textIndent: "-16px",
                      }}
                    >
                      {/* <i
                        className="fal fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                      <p
                        className=""
                        style={{ fontSize: "16px", marginLeft: "0px" }}
                      >
                        <i
                          className="far fa-check fa-lg"
                          style={{
                            color: "#fec600",
                            marginRight: "7px",
                            marginLeft: "2px",
                          }}
                        ></i>
                        Custom crafted itinerary that includes list of
                        activities & restaurant recommendations.
                      </p>
                    </li>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "left",
                        marginBottom: "18px",
                        marginLeft: "33px",
                        textIndent: "-16px",
                      }}
                    >
                      {/* <i
                        className="far fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                      <p
                        className=""
                        style={{ fontSize: "16px", marginLeft: "0px" }}
                      >
                        <i
                          className="far fa-check fa-lg"
                          style={{
                            color: "#fec600",
                            marginRight: "7px",
                            marginLeft: "2px",
                          }}
                        ></i>
                        Unlimited chat with your travel plan designer
                      </p>
                    </li>

                    <li
                      style={{
                        display: "flex",
                        alignItems: "left",
                        marginBottom: "18px",
                        marginLeft: "33px",
                        textIndent: "-16px",
                      }}
                    >
                      {/* <i
                        className="far fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                      <p
                        className=""
                        style={{
                          fontSize: "16px",
                          marginLeft: "0px",
                          marginLeft: "2px",
                        }}
                      >
                        <i
                          className="far fa-check fa-lg"
                          style={{
                            color: "#fec600",
                            marginRight: "7px",
                            marginLeft: "2px",
                          }}
                        ></i>
                        Accommodation recommendations
                      </p>
                    </li>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "left",
                        marginBottom: "18px",
                        marginLeft: "33px",
                        textIndent: "-16px",
                      }}
                    >
                      {/* <i
                        className="far fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                      <p
                        className=""
                        style={{ fontSize: "16px", marginLeft: "0px" }}
                      >
                        <i
                          className="far fa-check fa-lg"
                          style={{
                            color: "#fec600",
                            marginRight: "7px",
                            marginLeft: "2px",
                          }}
                        ></i>
                        Local travel tips
                      </p>
                    </li>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "left",
                        marginBottom: "18px",
                        marginLeft: "33px",
                        textIndent: "-16px",
                      }}
                    >
                      {/* <i
                        className="far fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                      <p
                        className=""
                        style={{ fontSize: "16px", marginLeft: "0px" }}
                      >
                        <i
                          className="far fa-check fa-lg"
                          style={{
                            color: "#fec600",
                            marginRight: "7px",
                            marginLeft: "2px",
                          }}
                        ></i>
                        Travel checklist
                      </p>
                    </li>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "left",
                        marginBottom: "18px",
                        marginLeft: "33px",
                        textIndent: "-16px",
                      }}
                    >
                      {/* <i
                        className="far fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                      <p
                        className=""
                        style={{ fontSize: "16px", marginLeft: "0px" }}
                      >
                        <i
                          className="far fa-check fa-lg"
                          style={{
                            color: "#fec600",
                            marginRight: "7px",
                            marginLeft: "2px",
                          }}
                        ></i>
                        Unlimited revisions of your itinerary
                      </p>
                    </li>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "left",
                        marginBottom: "18px",
                        marginLeft: "33px",
                        textIndent: "-16px",
                      }}
                    >
                      {/* <i
                        className="far fa-check fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                      <p
                        className=""
                        style={{ fontSize: "16px", marginLeft: "0px" }}
                      >
                        <i
                          className="far fa-check fa-lg"
                          style={{
                            color: "#fec600",
                            marginRight: "7px",
                            marginLeft: "2px",
                          }}
                        ></i>
                        An offline copy of your travel plan
                      </p>
                    </li>
                  </ul>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <a
                      // onClick={e => {
                      //   // if (authContext) navigate("/app/dashboard")
                      //   // else navigate("/signup-as-traveler")
                      //   // analytics.logEvent("viewed_sample_travelplan")
                      //   navigate("/app/sampletravelplan/")
                      // }}
                      href="/sample-travel-plan"
                      style={{
                        cursor: "pointer",
                        textAlign: "center",
                        // padding: "10px",
                        // margin: "15px",
                        // border: "1px solid #ebebeb",
                        borderRadius: "14px",
                        // maxWidth: "500px",
                        // backgroundColor: "#ebebeb",
                      }}
                    >
                      <p
                        style={{
                          textTransform: "uppercase",
                          // fontSize: "16px",
                          marginBottom: "20px",
                          // textDecorationColor: "#fec600",
                          // textDecoration: "underline",
                          fontWeight: "600",
                          maxWidth: "250px",
                          borderRadius: "6px",
                        }}
                        className="button is-warning is-small"
                      >
                        View a sample travel plan
                      </p>
                      <LazyLoadImage
                        src={paris}
                        alt="paris sample travel itinerary"
                        effect="blur"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-1-desktop"></div>
          </div>
        </div>
        <div
          style={{ maxWidth: "400px", margin: "30px auto", marginTop: "80px" }}
        >
          <button
            // className="button is-medium is-warning is-inverted brand-shadow"
            className="button is-medium is-warning brand-shadow"
            style={{ width: "100%" }}
            onClick={e => {
              if (authContext) navigate("/app/dashboard")
              else navigate("/signup-as-traveler")
            }}
          >
            Get started
          </button>
          {/* {showRightContent()} */}
        </div>
        <div
          style={{
            maxWidth: "270px",
            margin: "0px auto",
            marginBottom: "20px",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              letterSpacing: "1px",
              color: "rgba(14,19,24,.45)",
              textAlign: "center",
              marginTop: "60px",
            }}
          >
            WHAT TRAVELERS SAY ABOUT US
          </p>
        </div>

        {/* <div className="columns">
          <div className="column is-2-desktop"></div>
          <div className="column is-8-desktop">
            {" "}
            <TestimonialsCardComponent
              isTraveler={true}
              imgUrl={testimonial2}
              testimonial={`As an experienced traveler, I am accustomed to planning trip details from start to finish. When a recent trip was changed at the last minute from San Diego to Boca Raton, I was left scrambling to figure out what to do in an unfamiliar city and very little time to figure it out. Voyay!ge was instrumental in providing me with an itinerary that fit my needs and budget. After answering a questionnaire about my travel preferences, I was able to pick a trip planner who had similar interests. My trip planner, Emma, did an amazing job finding places that matched my preferences, and she made as many adjustments as I requested. She was responsive, detailed, and very easy to work with. The fact that I had access to unlimited chats and adjustments put my mind at ease. That said, the original itinerary was so on target with my preferences, very few changes were needed. The itinerary itself was very easy to read and use and was invaluable for a smooth travel experience. I highly recommend Voyay!ge and will use them again in the future without hesitation.`}
              designerName={"Kym @1onemileatatime_"}
              profileUrl={testimonial2}
              designationText={"Trip to Boca Raton"}
            />
          </div>
          <div className="column is-2-desktop"></div>
        </div> */}

        <div className="columns">
          <div className="column is-2-desktop"></div>
          <div className="column is-4-desktop">
            <TestimonialsCardComponent
              isTraveler={true}
              imgUrl={testimonial1}
              testimonial={`My son and I were so happy to have the help of voyayge on our cross-country road trip. It took the stress out of planning and partaking in the adventure. Not having to worry about what to do or where to eat, and having an itinerary to refer to for activities — with an expected amount of time for each, resulted in me being able to be ‘fully present’ in the moment. I’d definitely use their services again!`}
              designerName={"Jennifer"}
              profileUrl={"/app/userprofiles/Ag3FtceuPXVZxsZnFSA2sUIoXjn2"}
              designationText={"Took a 5 day cross country trip to Texas"}
            />
          </div>
          <div className="column is-4-desktop">
            <TestimonialsCardComponent
              isTraveler={true}
              imgUrl={testimonial2}
              testimonial={`Huge shout out to voyayge for planning a perfect trip to Chicago!`}
              designerName={"Janki @1onemileatatime_"}
              profileUrl={testimonial2}
              designationText={"A weekend in Chicago"}
            />
          </div>
          <div className="column is-2-desktop"></div>
        </div>
        <div className="columns">
          <div className="column is-2-desktop"></div>
          <div className="column is-8-desktop">
            <TestimonialsCardComponent
              isTraveler={true}
              imgUrl={
                "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2F3w4J2mzkgQeuFgjXP99lY3dtb0h1%2Fprofile%2FprofileImage.png?alt=media&token=0ef7ef6c-e076-4b86-ae1d-a31c966da8fd"
              }
              testimonial={`As an experienced traveler, I am accustomed to planning trip details from start to finish. When a recent trip was changed at the last minute from San Diego to Boca Raton, I was left scrambling to figure out what to do in an unfamiliar city and very little time to figure it out. voyayge was instrumental in providing me with an itinerary that fit my needs and budget. After answering a questionnaire about my travel preferences, I was able to pick a trip planner who had similar interests. My trip planner, Emma, did an amazing job finding places that matched my preferences, and she made as many adjustments as I requested. She was responsive, detailed, and very easy to work with. The fact that I had access to unlimited chats and adjustments put my mind at ease. That said, the original itinerary was so on target with my preferences, very few changes were needed. The itinerary itself was very easy to read and use and was invaluable for a smooth travel experience. I highly recommend voyayge and will use them again in the future without hesitation.`}
              designerName={"Kym"}
              profileUrl={
                "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2F3w4J2mzkgQeuFgjXP99lY3dtb0h1%2Fprofile%2FprofileImage.png?alt=media&token=0ef7ef6c-e076-4b86-ae1d-a31c966da8fd"
              }
              designationText={"Trip to Boca Raton"}
            />
          </div>

          <div className="column is-2-desktop"></div>
        </div>
        {/* <div className="columns">
          <div className="column is-2-desktop"></div>
          <div className="column is-8-desktop">
            <TestimonialsCardComponent
              isTraveler={true}
              imgUrl={testimonial3}
              testimonial={`I was planning a long relaxing girls' weekend. A friend recommended trying Voyay!ge. So glad I did. The process was extremely easy and I got to choose a trip designer based on their profile and places they have personally traveled to. Using Voyay!ge saved me an enormous amount of time. Our trip designer incorporated everything we wanted to see and created an itinerary that just flowed together, even the lunch and dinner places recommendations depending on the day where we were hiking and clothing attire that would be required by the restaurant. It is that detailed (if you want it to be) and extremely convenient. The time I saved working with Voyay!ge worth every penny. I would happily pay more to know that every trip that is put together was personally experienced and recommended by a human. It's like having a friend who just came back from a trip you are going on and is ready to help you plan yours. I am eager to plan my next trip. Thank you Voyay!ge for a great first experience. You have a repeat customer. `}
              designerName={"Alina"}
              profileUrl={testimonial3}
              designationText={"Trip to Yellowstone National Park"}
            />
          </div>

          <div className="column is-2-desktop"></div>
        </div> */}
        <div
          style={{
            maxWidth: "75px",
            margin: "0px auto",
            marginBottom: "40px",
          }}
        >
          <img src={wiggle} alt="" />
        </div>
      </section>

      <section
        className="section is-small"
        style={{ backgroundColor: "#FCD136", borderRadius: "14px" }}
        // id="newsletter-subscribe"
      >
        <div
          className="container"
          style={
            {
              // minHeight: "325px",
              // display: "flex",
              // flexWrap: "wrap",
              // justifyContent: "center",
            }
          }
        >
          {" "}
          <h2 className="section-header">
            <span
              style={{
                color: "#4a4a4a",
              }}
            >
              FEATURED DESIGNERS
            </span>
            <span
              style={{
                color: "#fff",
              }}
            >
              .
            </span>
          </h2>
          {/* <p
            style={{
              fontSize: "32px",
              fontWeight: "800",
              lineHeight: "1.7",
              marginBottom: "65px",
              textTransform: "uppercase",
              textAlign: "center",
              // color: "#fec600",
            }}
          >
            Featured Travel Designers
          </p> */}
          {/* <button
            className="button"
            onClick={() => {
              getRandomDesigners()
            }}
          >
            test
          </button> */}
          <div
            className="featured-designer-container"
            style={{
              marginTop: "70px",
            }}
          >
            <FetchAsyncDesignerCards />
          </div>
          <div
            style={{
              width: "178px",
              margin: "0 auto",
              marginTop: "30px",
            }}
          >
            <button
              className="button is-warning"
              onClick={() => {
                navigate("/travel-designers")
              }}
              style={{ backgroundColor: "white" }}
            >
              View All Designers
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <a
            style={{
              color: "#4a4a4a",
              // float: "right",
              fontWeight: "500",
              textDecoration: "underline",
              textDecorationColor: "white",
              cursor: "pointer",
              fontSize: "14px",
            }}
            onClick={() => {
              navigate("/become-a-trip-designer")
            }}
          >
            Become a trip designer? learn how.
          </a>
        </div>
      </section>

      <section
        className="section is-medium article-card-section"
        style={{}}
        // id="newsletter-subscribe"
      >
        <div className="container">
          {/* {displayCarousel()} */}
          <h2 className="section-header">
            <span
              style={{
                color: "#4a4a4a",
              }}
            >
              TRAVEL<br></br>INSPIRATION
            </span>
            <span
              style={{
                color: "#fec600",
              }}
            >
              .
            </span>
          </h2>
          <div
            style={{
              minHeight: "325px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <ArticleCards
              imageSrc="https://blog.voyayge.com/content/images/2021/09/21.jpg"
              heading="20 Fall Destinations in the USA for a fun family weekend"
              extract="Fall is that time of the year where the leaves change their colors and turn golden to renew themselves. The beautiful tourist attractions in the USA become even more enjoyable during fall."
              postUrl="https://blog.voyayge.com/20-fall-destinations-in-the-usa-for-a-fun-family-weekend/"
            />

            <ArticleCards
              imageSrc="https://blog.voyayge.com/content/images/2021/09/1364px-Van_Gogh_-_Starry_Night_-_Google_Art_Project-1.jpg"
              heading="12 destinations from famous paintings you can visit in real life"
              extract="England's churches. Temples in the Middle East. Beaches in Germany. All of them have served as sources of inspiration for some of the world's greatest artists, who have transformed natural landscapes and landmarks into works of art."
              postUrl="https://blog.voyayge.com/12-destinations-from-famous-paintings-you-can-visit-in-real-life/"
            />

            <ArticleCards
              imageSrc="https://blog.voyayge.com/content/images/2021/09/photo-1603378995290-8d4ce0495ddd.jpeg"
              heading="The Complete Guide to France - Where to Go, What to Eat and Tips"
              extract="An introduction to France's must-visit destinations and the best time to visit them. Plus, a list of things to eat while in France and top tips to remember."
              postUrl="https://blog.voyayge.com/the-complete-guide-to/"
            />
            <ArticleCards
              imageSrc="https://blog.voyayge.com/content/images/2020/12/snowscat-_7RIy-UNfnI-unsplash.jpg"
              heading="Visiting Patagonia"
              extract="Patagonia runs through both Argentina and Chile. The best time
                    to visit the region ranges from October to March, during the"
              postUrl="https://blog.voyayge.com/patagonia/"
            />

            <ArticleCards
              imageSrc="https://images.unsplash.com/photo-1511860810434-a92f84c6f01e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDI0fHxtb3JvY2NvfGVufDB8fHx8MTYyMjMxOTI1OA&ixlib=rb-1.2.1&q=80&w=2000"
              heading="Ten Must-See Wonders of Morocco"
              extract="What happens when the best of Arab and African influences meet
              over endless deserts and pristine seas and colorful"
              postUrl="https://blog.voyayge.com/wonders-of-morocco/"
            />

            <ArticleCards
              imageSrc="https://blog.voyayge.com/content/images/2021/02/pablo-fierro-zOXUvQ3Xo3s-unsplash.jpg"
              heading="10 Unforgettable Road Trips Across National Parks in the USA"
              extract="From East to West, America’s National Parks bring people closer to awe-inspiring natural beauty. Explore everything from incredible landscapes to abundant wildlife on these road trips across National Parks in the USA that you will remember for ages."
              postUrl="https://blog.voyayge.com/10-unforgettable-road-trips-across-national-parks-in-the-usa/"
            />

            <ArticleCards
              imageSrc="https://images.unsplash.com/photo-1579803978998-084e5be7ad9a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxMTc3M3wwfDF8c2VhcmNofDExfHxHcm8lQzMlOUZnbG9ja25lcnxlbnwwfHx8&ixlib=rb-1.2.1&q=80&w=2000"
              heading="Europe's Top Scenic Drives"
              extract="These drives allow you to experience all Europe's splendor, spectacular scenery, and breathtaking tourist attractions, making them a memorable, once in a lifetime experience."
              postUrl="https://blog.voyayge.com/top-10-scenic-drives-in-europe-to-take-in-2021/"
            />

            <ArticleCards
              imageSrc="https://images.unsplash.com/photo-1590856028965-5683c4b48ad0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDR8fG5pY2FyYWd1YXxlbnwwfHx8fDE2MjI1OTYwMjY&ixlib=rb-1.2.1&q=80&w=2000"
              heading="Nicaragua - The Hidden Gem of Central America"
              extract="When you think of great travel destinations in Central America, Nicaragua may not be the first place that pops into your mind but perhaps it should. This small country has as much to offer as neighbor Costa Rica but at a fraction of the price and with considerably fewer tourists. "
              postUrl="https://blog.voyayge.com/nicaragua-the-hidden-gem-of-central-america/"
            />

            <ArticleCards
              imageSrc="https://images.unsplash.com/photo-1566706546199-a93ba33ce9f7?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=2000&fit=max&ixid=eyJhcHBfaWQiOjExNzczfQ"
              heading="Angkor Wat – Mysteries & Myths"
              extract="Angkor Archaeological Park ranks among the most visually striking, breathtaking UNESCO World Heritage sites on the planet."
              postUrl="https://blog.voyayge.com/visiting-angkor-wat-and-angkor-thom-mysteries-and-mythologies-behind-the-ruins/"
            />
          </div>
          <div style={{ maxWidth: "260px", margin: "50px auto" }}>
            <a
              className="button is-warning"
              href="https://blog.voyayge.com"
              // onClick={() => {
              //   window.open("https://blog.voyayge.com", "_blank")
              // }}
            >
              View more articles like these
            </a>
          </div>
        </div>
      </section>
      {/* <section className="section is-medium">
        <div className="container">
          <h2 className="section-header">
            <span
              style={{
                color: "#4a4a4a",
              }}
            >
              PLAN A TRIP
            </span>
            <span
              style={{
                color: "#fec600",
              }}
            >
              .
            </span>
          </h2>
          <div className="tabs">
            <ul>
              <li
                className={`${activePATtab === "popular" ? "is-active" : ""}`}
              >
                <a
                  onClick={() => {
                    setActivePATtab("popular")
                  }}
                >
                  Popular Destinations
                </a>
              </li>
              <li
                className={`${
                  activePATtab === "usnationalparks" ? "is-active" : ""
                }`}
              >
                <a
                  onClick={() => {
                    setActivePATtab("usnationalparks")
                  }}
                >
                  US National Parks
                </a>
              </li>
              <li className={`${activePATtab === "states" ? "is-active" : ""}`}>
                <a
                  onClick={() => {
                    setActivePATtab("states")
                  }}
                >
                  States
                </a>
              </li>
              <li
                className={`${activePATtab === "countries" ? "is-active" : ""}`}
              >
                <a
                  onClick={() => {
                    setActivePATtab("countries")
                  }}
                >
                  Countries
                </a>
              </li>
            </ul>
          </div>
          <div
            style={{
              minHeight: "325px",
              marginTop: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                visibility: `${
                  activePATtab === "popular" ? "visible" : "hidden"
                }`,
              }}
            >
              <ShowPlanATrips type={"popular"} />
            </div>
            <div
              style={{
                position: "absolute",
                top: "174px",
                left: "0px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                visibility: `${
                  activePATtab === "states" ? "visible" : "hidden"
                }`,
              }}
            >
              <ShowPlanATrips type={"states"} />
            </div>
            <div
              style={{
                position: "absolute",
                top: "174px",
                left: "0px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                visibility: `${
                  activePATtab === "countries" ? "visible" : "hidden"
                }`,
              }}
            >
              <ShowPlanATrips type={"countries"} />
            </div>
            <div
              style={{
                position: "absolute",
                top: "174px",
                left: "0px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                visibility: `${
                  activePATtab === "usnationalparks" ? "visible" : "hidden"
                }`,
              }}
            >
              <ShowPlanATrips type={"usnationalparks"} />
            </div>
          </div>
        </div>
      </section> */}
      <section
        className="section is-small newsletter-container "
        style={{ display: "flex" }}
        id="newsletter-subscribe"
      >
        <div className="container is-vcentered" style={{}}>
          <NewsletterSubscription />
        </div>
      </section>
      <section
        className="section is-small"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",

          borderRadius: "0px",
          //minHeight: "100vh",
          backgroundColor: "#fff",
          // borderLeft: "1px solid #fec600",
          //borderRight: "1px solid #fec600",
          //backgroundColor: "#f9f9f9",
        }}
        id="faq"
      >
        <div className="container">
          <p className="section-header">
            <span
              style={{
                color: "#4a4a4a",
              }}
            >
              FAQs
            </span>
            <span
              style={{
                color: "#fec600",
              }}
            >
              .
            </span>
          </p>

          {/* <p style={{ fontSize: "37px", fontWeight: "300" }}>Traveler</p> */}
          {faqWrapper(
            <Accordion
              header={"How does it work?"}
              data={
                "You create a travel job by providing details on what you want in a trip and then pick a designer. The designer will then do all the research required to create you a custom curated travel plan based on your requirements."
              }
            />
          )}
          {faqWrapper(
            <Accordion
              header={"What is included when I order a custom plan?"}
              data={
                "You'll get a day to day travel plan that is built based on your preferences. This travel plan includes local attractions, dining suggestions, accommodation recommendations and local travel tips."
              }
            />
          )}
          {faqWrapper(
            <Accordion
              header={"Is a real person going to work on my travel itinerary?"}
              data={
                "Yes! You will also have unlimited online correspondence with the designer to communicate any additional preferences or tweak existing ones."
              }
            />
          )}
          {faqWrapper(
            <Accordion
              header={"Why should I use your service?"}
              data={
                "A typical traveller spends a lot of time doing research to build a travel plan. This is the traveler's pain point that we at voyayge are passionate about solving."
              }
            />
          )}
          {faqWrapper(
            <Accordion
              header={"Is my information shared with anyone else?"}
              data={
                "No, we take the privacy of your data very seriously. Other than the designer you have selected to work with, your travel plan and your travel information will not be shared with anyone else."
              }
            />
          )}
          {faqWrapper(
            <Accordion
              header={
                "There are so many travel plans available online why do I need your service?"
              }
              data={
                "Every traveller has specific requirements and preferences that are factored in during the planning phase of a trip. These could range from the choice of cuisines, types of attractions, dietary considerations, transportation preferences, specific local cultural explorations, etc. Typical cookie-cutter travel plans do not fullfil these requirements. A real person needs to pick and choose the activities carefully to curate an ideal travel experience."
              }
            />
          )}
          {faqWrapper(
            <Accordion
              header={"How long does the process take?"}
              data={
                "A typical itinerary should be available for review in 7 days from the time the job is confirmed."
              }
            />
          )}

          <div
            style={{ background: "", padding: "00px 0px", marginTop: "40px" }}
            className=""
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                textAlign: "right",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
                color: "rgba(74,74,74,0.5)",
              }}
            >
              More questions? <br></br>{" "}
              <span
                style={{
                  fontSize: "22px",
                  fontWeight: "400",
                  textAlign: "right",
                  textTransform: "none",
                  color: "rgba(74,74,74,0.8)",
                  // marginBottom: "17px",
                }}
              >
                We'd be glad to answer{" "}
              </span>
              <br></br>
              {/* <span style={{ fontSize: "47px", fontWeight: "800" }}>
                Get In Touch
              </span>{" "} */}
              <a
                className="link"
                style={{
                  fontSize: "45px",
                  fontWeight: "800",
                  textAlign: "right",
                  marginBottom: "50px",
                  color: "#fec600",
                  lineHeight: "1.0",
                  marginTop: "15px",
                  cursor: "pointer",
                }}
                href="/contact"
              >
                <span
                  style={{
                    color: "rgba(74,74,74,0.8)",
                  }}
                >
                  GET<br></br>IN TOUCH
                </span>
                <span
                  style={{
                    color: "#fec600",
                  }}
                >
                  .
                </span>
              </a>{" "}
            </p>
          </div>
        </div>
      </section>
      <section
        className="section is-small"
        style={{}}
        id="newsletter-subscribe"
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{ margin: "20px", cursor: "pointer" }}
            onClick={() => {
              navigate("/about#commitments")
            }}
          >
            <LazyLoadImage
              src={climate}
              width="75px"
              // height="111px"
              alt="build your profile"
              effect="blur"
            />
          </div>
          <div
            style={{ margin: "20px", cursor: "pointer" }}
            onClick={() => {
              navigate("/about#commitments")
            }}
          >
            <LazyLoadImage
              src={tree}
              width="75px"
              // height="111px"
              alt="build your profile"
              effect="blur"
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default LandingPage
